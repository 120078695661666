import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts today!  We will judge today during class
for those signed up for the Open as well as on Monday (rest day) and
certain times this weekend tba.`}</em></strong></p>
    <p><strong parentName="p">{`Workout 17.1`}</strong></p>
    <p>{`For time:\\
10 dumbbell snatches\\
15 burpee box jump-overs\\
20 dumbbell snatches\\
15 burpee box jump-overs\\
30 dumbbell snatches\\
15 burpee box jump-overs\\
40 dumbbell snatches\\
15 burpee box jump-overs\\
50 dumbbell snatches\\
15 burpee box jump-overs`}</p>
    <p>{`Men use 50-lb. dumbbell and 24-in. box, Women use 35-lb. dumbbell and
20-in. box.`}</p>
    <p>{`Time cap: 20 minutes`}</p>
    <p>{`::: {.page title=“Page 2”}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p">{`VARIATIONS`}</strong></p>
    <p><em parentName="p">{`Rx’d: (Ages 16-54)`}</em>{`\\
Men use 50-lb. dumbbell and 24-in. box Women use 35-lb. dumbbell and
20-in. box`}</p>
    <p><em parentName="p">{`Masters 55+:`}</em></p>
    <p>{`Men use 35-lb. dumbbell and 24-in. box, step-ups OK Women use 20-lb.
dumbbell and 20-in. box, step-ups OK
:::`}</p>
    <p>{`::: {.column}
`}<em parentName="p">{`Scaled: (Ages 16-54)`}</em>{`\\
Men use 35-lb. dumbbell and 20-in. box, step-ups OK Women use 20-lb.
dumbbell and 20-in. box, step-ups OK`}</p>
    <p><em parentName="p">{`Scaled Masters 55+:`}</em></p>
    <p>{`Men use 20-lb. dumbbell and 20-in. box, step-ups OK Women use 10-lb.
dumbbell and 20-in. box, step-ups OK`}</p>
    <p><strong parentName="p">{`MOVEMENT STANDARDS`}</strong></p>
    <p>{`DUMBBELL SNATCHES`}</p>
    <p>{`The dumbbell snatch starts with the dumbbell on the ground and nishes
with the dumbbell directly overhead. At the bottom of the movement, BOTH
heads of the dumbbell must touch the ground. The dumbbell must be lifted
overhead in one motion. Touch-and-go is permitted. Bouncing the dumbbell
is not allowed. Athletes must alternate arms after each repetition and
may not alternate until a successful rep is achieved. The non-lifting
hand and arm may not be in contact with the body during the repetition.`}</p>
    <p>{`::: {.page title=“Page 2”}
::: {.layoutArea}
::: {.column}
At the top, the arms, hips, and knees must be fully locked out with the
dumbbell clearly over the middle of the athlete’s body when viewed from
pro le. Once the athlete has reached lockout, the repetition will count.
The athlete may choose to do a split style snatch. However, both feet
must return and be in line under the athlete’s body while the dumbbell
is locked out overhead for the repetition to count.`}</p>
    <p>{`::: {.page title=“Page 3”}
::: {.layoutArea}
::: {.column}
BURPEE BOX JUMP-OVER`}</p>
    <p>{`::: {.page title=“Page 3”}
::: {.layoutArea}
::: {.column}
The burpee box jump-over starts with the athlete facing the box while
touching their chest and thighs to the ground, and nishes with the
athlete jumping over the box. In the bottom position, to ensure the
athlete remains perpen- dicular to the box on each rep, the center of
the athlete’s chest must be on the line, and the feet and hands must be
straddling the line.
:::`}</p>
    <p>{`::: {.column}
There is no requirement to stand tall while on top of the box. A
two-foot takeo is always required, and only the athlete’s feet may touch
the box. The athlete may jump on top of the box using a two-foot landing
and then jump or step o to the other side, or the athlete may jump
completely over the box. If jumping over the box, the feet must go over
the box, not around it, and the athlete must use a two-foot landing.
:::`}</p>
    <p>{`::: {.column}
Each rep is counted when the athlete lands on the ground on the opposite
side, where they may begin their next rep.`}</p>
    <p>{`::: {.page title=“Page 3”}
::: {.layoutArea}
::: {.column}
`}<em parentName="p">{`BURPEE BOX STEP-OVER (SCALED AND MASTERS 55+ ONLY)`}</em></p>
    <p>{`::: {.page title=“Page 3”}
::: {.layoutArea}
::: {.column}
The burpee box step-over starts with the athlete facing the box while
touching their chest and thighs to the ground, and nishes with the
athlete jumping or stepping over the box. In the bottom position, to
ensure the athlete remains perpendicular to the box on each rep, the
center of the athlete’s chest must be on the line, and the feet and
hands must be straddling the line.
:::`}</p>
    <p>{`::: {.column}
There is no requirement to stand tall while on top of the box. Only the
athlete’s feet may touch the box. The athlete may step up or jump on top
of the box and then jump or step down on the other side, or the athlete
may jump completely over the box. If jumping over the box, the feet must
go over the box, not around it.
:::`}</p>
    <p>{`::: {.column}
Each rep is counted when the athlete lands on the ground on the opposite
side, where they may begin their next rep.
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::
:::`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      